import React, { useState } from 'react';
import './Sidebar.scss';
import { PanelMenu } from 'primereact/panelmenu';
import AddParties from '../Pages/Parties/AddParties/AddParties';
import { useNavigate } from 'react-router-dom';
import img from '../../assets/img/avatar.webp'

function Sidebar() {

    const [visible, setVisible] = useState(false);
    const [activeItem, setActiveItem] = useState('');
    const [collapsed, setCollapsed] = useState(false);
    const navigator = useNavigate();


    const toggleSidebar = () => {
        setCollapsed(!collapsed);
    };


    const onClickRouter = (path, item) => {
        setActiveItem(path);
        navigator(path.toLowerCase());
        localStorage.removeItem('saleReturn');
        localStorage.removeItem('saleInvoice');
        localStorage.removeItem('purchanseBill');
        localStorage.removeItem('purchaseReturn');
        localStorage.removeItem('expenseId');
        localStorage.removeItem('item');
        localStorage.removeItem('saleOrder');
    }

    const itemRenderer = (item, index) => (
        <a key={index} className={`flex align-items-center px-3 py-2 cursor-pointer ${activeItem === item.name ? 'active' : ''}`} onClick={() => onClickRouter(item.name)}>
            <div className='text'>
                <span className={`${item.icon} text-primary`} />
                <h6 className={`mx-2`}>{item.label}</h6>
            </div>
            {item.arrow && <i className={`pi ${item.arrow}`} onClick={(e) => handleArrowClick(e, item)}></i>}
        </a>
    );

    const itemRenderer2 = (item, index) => (
        <a key={index} className="flex align-items-center px-3 py-2 cursor-pointer" >
            <div className='text' >
                <span className={`${item.icon} text-primary`} />
                <h6 className={`mx-2`}>{item.label}</h6>
            </div>
            {item.arrow && <i className={`pi ${item.arrow}`}  ></i>}
        </a>
    );

    const handleArrowClick = (e, item) => {
        e.stopPropagation();
        if (item.name === 'Parties' && e.target.classList.contains('pi-plus')) {
            setVisible(true);
        } else if (item.name === 'Items' && e.target.classList.contains('pi-plus')) {
            navigator('./additems');
        } else if (item.name === 'SaleInvoices' && e.target.classList.contains('pi-plus')) {
            navigator('./addSaleInvoices');
        } else if (item.name === 'SaleReturn' && e.target.classList.contains('pi-plus')) {
            navigator('./addCreditNote');
        } else if (item.name === 'PurchaseBills' && e.target.classList.contains('pi-plus')) {
            navigator('./addPurchaseBills');
        } else if (item.name === 'PurchaseReturn' && e.target.classList.contains('pi-plus')) {
            navigator('./addDebitNote');
        } else if (item.name === 'Expensive' && e.target.classList.contains('pi-plus')) {
            navigator('./expensive/addExpensive');
        }
        else {
            if (e.target.classList.contains('pi-plus')) {
                alert(`Arrow clicked for ${item.label}`);
            }
        }
    };

    const items = [
        {
            label: 'Home',
            name: 'Home',
            icon: 'pi pi-home',
            template: itemRenderer,
        },
        {
            label: 'Parties',
            name: 'Parties',
            icon: 'pi pi-users',
            arrow: "pi-plus",
            template: itemRenderer,
        },
        {
            label: 'Items',
            name: 'Items',
            icon: 'pi pi-objects-column',
            arrow: "pi-plus",
            template: itemRenderer,
        },
        {
            label: 'Sale',
            name: 'Sale',
            icon: 'pi pi-book',
            arrow: "pi-angle-down",
            template: itemRenderer2,
            items: [
                {
                    name: 'SaleInvoices',
                    label: 'Sale Invoices',
                    arrow: "pi-plus",
                    template: itemRenderer
                }, {
                    name: 'SaleOrder',
                    label: 'Sale Order',
                    arrow: "pi-plus",
                    template: itemRenderer
                }, {
                    name: 'SaleReturn',
                    label: 'Sale Return',
                    arrow: "pi-plus",
                    template: itemRenderer
                },
            ],
        },
        {
            label: 'Purchase',
            icon: 'pi pi-shopping-cart',
            arrow: "pi-angle-down",
            template: itemRenderer2,
            items: [
                {
                    name: 'PurchaseBills',
                    label: 'Purchase Bills',
                    arrow: "pi-plus",
                    template: itemRenderer
                },
                {
                    name: 'PurchaseReturn',
                    label: 'Purchase Return',
                    arrow: "pi-plus",
                    template: itemRenderer
                }
            ]
        },
        {
            label: 'Expensive',
            name: 'Expensive',
            icon: 'pi pi-wallet',
            arrow: "pi-plus",
            template: itemRenderer,
        },
        {
            name: 'Account',
            label: 'Account',
            icon: 'pi pi-book',
            arrow: "pi-angle-down",
            template: itemRenderer2,
            items: [
                {
                    name: 'PaymentIn',
                    label: 'Payment In',
                    // arrow: "pi-plus",
                    template: itemRenderer
                },
                {
                    name: 'PaymentOut',
                    label: 'Payment Out',
                    // arrow: "pi-plus",
                    template: itemRenderer
                }
            ]
        },
        {
            label: 'Report',
            name: 'Report',
            icon: 'pi pi-chart-line',
            // arrow: "pi-plus",
            template: itemRenderer,
        },
        // {
        //     label: 'Bank & Cash',
        //     name: 'Bank-Cash',
        //     icon: 'pi pi-building-columns',
        //     arrow: "pi-plus",
        //     template: itemRenderer,
        // },
        // {
        //     label: 'Settings',
        //     name: 'Settings',
        //     icon: 'pi pi-cog',
        //     arrow: "pi-angle-down",
        //     template: itemRenderer2,
        //     items: [
        //         {
        //             label: 'Print',
        //             name: 'Print',
        //             template: itemRenderer,
        //         },
        //     ]
        // },
    ];


    return (
        <div id='Sidebar' className={collapsed ? 'collapsed' : ''}>
            <div className='main'>
                {/* <div className='top-button'>
                    <button onClick={toggleSidebar}>
                        {collapsed ? 'Expand' : 'Collapse'}
                    </button>
                </div> */}
                <div className='company'>
                    <div className='image'>
                        <img src={img} alt='image' />
                    </div>
                    <h2>AccoHisab<i className="pi pi-angle-right"></i> </h2>
                </div>

                <div className='menu'>
                    <div className="card flex justify-content-center">
                        <PanelMenu model={items} className="w-full" />
                    </div>
                </div>
                <div className="foter-title">
                    <h5 onClick={() => window.open('https://gadhiyatechnologies.com', '_blank')}> Developed by Gadhiya Technologies</h5>
                </div>

                <AddParties visible={visible} setVisible={setVisible} />

            </div>
        </div>
    );
};

export default Sidebar;