import React, { useContext, useEffect, useRef, useState } from 'react';
import './Header.scss'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useNavigate } from 'react-router-dom';
import { SocketContext } from '../SocketContext/SocketContext';
import { Post } from '../../Config/config';
import SnackBarNotification from '../SnackBarNotification/SnackBarNotification';

function Header() {
    const socket = useContext(SocketContext);
    // SankBar
    const [sankbar, setSankbar] = useState(false);
    const [sankbarText, setSankbarText] = useState("");
    const [sankbarColor, setSankbarColor] = useState("");
    const op = useRef(null);
    const navigator = useNavigate();

    const [fullaName, setFullName] = useState();
    const [emailId, setEmailId] = useState();
    const [shortName, setShortName] = useState();
    const [Id, setId] = useState();
    const [userId, setuserId] = useState();

    const logout = () => {
        // socket.emit('logout', { UserId: userId?.UserId, CompanyCode: userId?.CompanyCode, Token: Id, EUser: userId?.EUser });
        Post('User/LogOut', {
            UserId: userId?.UserId,
        }).then((res) => {
            if (res) {
                if (res.status === 1) { 
                    sessionStorage.clear();
                    localStorage.clear();
                }
            }
        })
    };

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        const Id = localStorage.getItem("ID");

        if (userData !== null && userData !== undefined) {
            setId(Id);
            setuserId(userData);
            setFullName(userData.Username);
            setEmailId(userData.UserEmail);
            setShortName(userData.Username?.substring(0, 1));
        } else {
            console.error('User data or ID is missing in localStorage');
        }

        socket.on('logoutSuccess', (data) => {
            if (data.status === 1) {
                navigator('/login');
            }
        });

        socket.on('logoutError', (data) => {
            console.error('Error logging out:', data.message);
        });

        return () => {
            socket.off('logoutSuccess');
            socket.off('logoutError');
        };
    }, [socket]);


    // useEffect(() => {
    //     const userData = JSON.parse(localStorage.getItem("userData"));
    //     if (userData !== null || undefined) {
    //         setFullName(userData.Username);
    //         setEmailId(userData.UserEmail);

    //         setShortName(userData.Username?.substring(0, 1));
    //     }
    // }, []);

    // profile
    const [isOpen, setIsOpen] = useState(false);
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const modalRef2 = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (modalRef2.current && !modalRef2.current.contains(e.target)) {
                setIsOpen(false);
            }
        };

        const handleScroll = () => {
            setIsOpen(false);
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
            window.addEventListener('scroll', handleScroll);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isOpen]);

    const setting =()=>{
        setIsOpen(false)
        setSankbar(true);
        setSankbarText("Settings is under maintenance.");
        setSankbarColor("error");
    }

    return (
        <div id='Header'  >
            <div className='main'>
                <div className='leftHeader'>
                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-search"></i>
                        </span>
                        <InputText placeholder="Search transactions" />
                    </div>
                </div>
                <div className='rightHeader'>
                    <div className='button'>
                        <Button label="Add Sale" onClick={() => navigator('/addSaleInvoices')} icon="pi pi-plus-circle" severity='danger' rounded raised />
                        <Button label="Add Purchase" onClick={() => navigator('/addPurchaseBills')} icon="pi pi-plus-circle" severity='info' rounded raised />
                        {/* <Button label="Add More" icon="pi pi-plus-circle" severity='info' outlined rounded raised /> */}
                    </div>
                    <div className="icons">
                        <Button type="button" icon="pi pi-bell" onClick={(e) => op.current.toggle(e)} />
                        <OverlayPanel ref={op}>
                            <div className='notifictions'>
                                <h2>Notifictions</h2>

                                <div className='contain'>
                                    <Button type="button" icon="pi pi-bell" />
                                    <h6>No Notifications yet!</h6>
                                    <p>Stay tuned! Notifications about your activity will show up here.</p>
                                </div>
                            </div>
                        </OverlayPanel>
                        {/* <Button type="button" icon="pi pi-cog" /> */}

                        <div className='letter_word_click'>
                            <div className={`letter_word ${isOpen ? 'open' : ''}`} onClick={() => handleToggle()}>
                                <h2>{shortName}</h2>
                            </div>

                            {isOpen && (
                                <React.Fragment>
                                    <div className='toggleBar' ref={modalRef2}>
                                        <div className='nameBar'>
                                            <div className='letter_word2'>
                                                <h2>{shortName}</h2>
                                            </div>
                                            <div className="username">
                                                <h6>{fullaName}</h6>
                                                <p>{emailId}</p>
                                            </div>
                                        </div>
                                        <nav className='navigation'>
                                            <ul>
                                                <li onClick={() => { navigator("/profile"); setIsOpen(false) }}>Profile</li>
                                                <li onClick={() => setting()}> Setting </li>
                                                <li className='hozaderline'></li>
                                                <li onClick={() => logout()}> Log Out </li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className='Backlayer' onClick={() => setIsOpen(false)}></div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <SnackBarNotification
                open={sankbar}
                text={sankbarText}
                color={sankbarColor}
                sankbar={(value) => setSankbar(value)}
            />

        </div>
    );
};

export default Header;