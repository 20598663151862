import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function KeyEventHandler() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.shiftKey) {
        if (event.key === 'S') {
          event.preventDefault();
          navigate('/addSaleInvoices');
        } else if (event.key === 'P') {
          event.preventDefault();
          navigate('/addPurchaseBills');
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigate]);

  return null;
}

export default KeyEventHandler;
