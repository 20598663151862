// App.js
import React, { useState, useEffect, useRef } from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./Routes";
import Layout from "./Routes/Layout";
import Login from './Login/Login';
import { jwtDecode } from 'jwt-decode';
import Page500 from './components/Pages/Page500/Page500';
import Page404 from './components/Pages/Page404/Page404';
import { SocketContext, socket } from './components/SocketContext/SocketContext';
import './App.scss'
import KeyEventHandler from './components/KeyEventHandler/KeyEventHandler ';

function App() {
  const [isLogin, setIsLogin] = useState(localStorage.getItem('ID') ? true : false);
  const inactivityTimeout = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsLogin(!!sessionStorage.getItem('header'));
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  const checkForUserData = () => {
    const userData = localStorage.getItem('userData');
    if (!userData) {
      window.location.assign('/404');
    }
  };

  useEffect(() => {
    if (isLogin === true) {
      checkForUserData();
    }
  }, []);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setIsLogin(!!localStorage.getItem('ID'));
  //   }, 100); 

  //   return () => clearInterval(intervalId);
  // }, []);

  useEffect(() => {
    if (window.location.pathname === "/" || window.location.pathname === "/login") {
      sessionStorage.removeItem("header");
      sessionStorage.removeItem("FRM_Code");
      localStorage.clear();
    }

    const handleActivity = () => {
      clearTimeout(inactivityTimeout.current);
      inactivityTimeout.current = setTimeout(() => {
        sessionStorage.removeItem("header");
        sessionStorage.removeItem("FRM_Code");
        localStorage.clear();
        setIsLogin(false);
        checkToken();
      }, 10 * 60 * 60 * 1000);
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    return () => {
      clearTimeout(inactivityTimeout.current);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, []);


  const checkToken = () => {
    const token = localStorage.getItem('ID');
    if (token) {
      const decodedToken = jwtDecode(token, "1234567890123456789");
      const currentTime = Date.now() / 1000;
      setIsLogin(decodedToken.exp >= currentTime);
    } else {
      setIsLogin(false);
    }
  };


  const router = createBrowserRouter([
    {
      element: isLogin ?
        <SocketContext.Provider value={socket}>
          <Layout />
          <KeyEventHandler />
        </SocketContext.Provider> : <Login />,
      errorElement: window.location.pathname === '500' ? <Page500 /> : <Page404 />,
      children: routes
    }
  ]);

  return <RouterProvider router={router} />;
}

export default App;
