import { createContext } from 'react';
import io from 'socket.io-client';
// http://192.168.1.102:3218
const socket = io('', {
  transports: ['websocket', 'polling'],
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 2000,
});

export const SocketContext = createContext(socket);
export { socket };
